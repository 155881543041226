<template>
  <div class="page page__content-wrapper">

    <page-item v-for="(item, idx) in data" :key="idx" :content="item"></page-item>

    <BottomNavBlock :prev-link="bottomNav.pre" :next-link="bottomNav.next"/>
  </div>
</template>

<script>
import BottomNavBlock from "@/components/Elements/BottomNavBlock/BottomNavBlock"
import PageItem from "../../../components/Elements/PageItem/PageItem"

export default {
  components: {
    PageItem,
    BottomNavBlock
  },

  data() {
    return {
      bottomNav: {
        pre: {
          url: "/brand/souvenir-products",
          text: "Сувенирная продукция"
        },
        next: {
          url: "/brand/interior",
          text: "Интерьер"
        }
      },
      data: [
        {
          type: "image",
          title: "Пресс-волл",
          images: [
            {
              img: "event-decoration-page/events-01.png",
              icons: [
                {
                  url: "../download-file/10-Events/Steor-Stand.zip",
                  icon: "icon-ai.svg"
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/var-style";
@import "../../../assets/style/text-style";
@import "../../../assets/style/page-block";
@import "../../../assets/style/buttons";

.page {
  margin-bottom: 192px;
  &__min-container {
    margin-bottom: 0;
  }
  &__link-block {
    display: flex;
    margin-left: 23px;
  }
}

///deep/.download-block {
//  margin-left: 24px;
//}


@media screen and (max-width: 1540px) {
  .page__content-wrapper {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 263px;
  }
}


@media screen and (max-width: 1320px) {
  .page__content-wrapper {
    padding-left: 0;
  }
}

@media screen and (max-width: 1216px) {
  .page__link-block.download-block {
    display: none;
  }
}

@media screen and (max-width: 1090px) {
  .page {
    margin-top: 0;
    padding-top: 18px;
  }
}

@media screen and (max-width: 896px) {
  .pages-wrap {
    .container {
      .page {
        &.page__content-wrapper {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  .page {
    margin-bottom: 161px;
    &__full-width {
      width: 100%;
    }

    &__download-block {
      display: none;
    }
  }
}


</style>
