import { render, staticRenderFns } from "./EventDecorationPage.vue?vue&type=template&id=815f821e&scoped=true&"
import script from "./EventDecorationPage.vue?vue&type=script&lang=js&"
export * from "./EventDecorationPage.vue?vue&type=script&lang=js&"
import style0 from "./EventDecorationPage.vue?vue&type=style&index=0&id=815f821e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "815f821e",
  null
  
)

export default component.exports